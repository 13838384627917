import axios from 'axios'
import {
    MessageBox,
    Message
} from 'element-ui'
import store from '@/store'
import main from "@/main"
import router from '@/router'

// create an axios instance
const service = axios.create({
    baseURL: '/', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 100000, // request timeout
    retryDelay: 1000, // 请求间隙
    retry: 2, // 重试次数
    // withCredentials: true, // 是否携带cookie信息
    // headers: {
    //     'machine-code': 'application/json; charset=*'
    // },
});
// 判断手机
function _isMobile() {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
}
// 请求拦截器
service.interceptors.request.use(
    (config) => {
        let url = String(window.location.hash).substring(1, 12);
        // console.log("请求拦截器信息",url);
        if (!_isMobile()) {
            let mcode = localStorage.getItem("machinecode");
            if (mcode) {
                config.headers['machine-code'] = mcode
            } else {
                if (url != "/screenshot") {
                    // axios.post("http://127.0.0.1:8081/DevCode").then(res => {
                    //     localStorage.setItem("machinecode", res.data.data);
                    // }).catch(error => {
                    // })
                }
            }
        }

        return config
    },
    error => {
        // do something with request error
        ////console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Cod
     */
    response => {
        const res = response.data
        res.code *= 1;
		let arr = location.href.split("/")
		let hashStr = arr[arr.length - 1]
		// console.log(res.code, hashStr,"index");
        if (res.code == 400302) {
            Message({
                showClose: true,
                message: res.msg || 'Error',
                type: 'error',
                duration: 2 * 1000
            })
            router.go(-1)
            return res
        }

        //    判断是否已登陆过
        if (res.code === 400301) {
            // Message({
            //     message: res.msg || 'Error',
            //     type: 'error',
            //     duration: 2 * 1000
            // })
            if (hashStr != "userLogin" && hashStr != "login" && hashStr != "userLogin?type=out" && hashStr != "login?type=out") {
                Message({
                    showClose: true,
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 2 * 1000
                })
                if (hashStr.indexOf("answer") > -1) {
                    return location.href = '/index.html#/userLogin'
                } else {
                    return location.href = '/index.html#/login'
                }

            } else {
                return res
            }
        }
        if (res.code == 400201) {
            return res
        }
        // 未登录
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 400200) {
            // Message({
            //     showClose: true,
            //     message: res.msg || 'Error',
            //     type: 'error',
            //     duration: 5 * 1000
            // })

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 500302) {
                // to re-login
                MessageBox.confirm(res.msg, '确定退出', {
                    confirmButtonText: 'Re-Login',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
            }
            // return Promise.reject(new Error(res.message || 'Error'))
            return res
        } else {
            return res
        }
    },
    error => {
        // console.log("响应拦截器",error);
        // console.log('err' + error) // for debug
        if (error != "Error: Network Error") {
            Message({
                showClose: true,
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error)
    }
)

export default service