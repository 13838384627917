var websock = null
var globalCallback = null
var data = null
var userToken = localStorage.getItem("usertoekn") ? localStorage.getItem("usertoekn") : ""
var baseURL = "http://tr.cqsqy.com"
var tmp = "23"
var dataInfo = []
let wsUrl = ""
import axios from 'axios'
// import qs from 'qs'
import { Main } from 'element-ui'
import { bind_websocket_id } from "../../src/api/login";
import { Notification } from 'element-ui';


if (window.location.protocol == 'https:') {
    wsUrl = `wss://${window.location.hostname}:2347`
} else {
    wsUrl = `ws://${window.location.hostname}:2347`
}

// 测试用
// if (window.location.hostname == '192.168.0.233') {
//     wsUrl = ' ws://39.101.223.195:2347'
// }
// 初始化weosocket
function initWebSocket() {
    // ws地址 -->这里是你的请求路径
    // ws://39.101.223.195:2346'
    var ws = wsUrl
    websock = new WebSocket(ws)
    websock.onmessage = function (e) {
        websocketonmessage(e)
    }
    websock.onclose = function (e) {
        websocketclose(e)
    }
    websock.onopen = function () {
        websocketOpen()
    }

    // 连接发生错误的回调方法
    websock.onerror = function () {
        console.log('WebSocket连接发生错误')
    }
}

// 实际调用的方法
function sendSock(agentData, callback) {
    globalCallback = callback
    data = agentData;
    if (websock.readyState === websock.OPEN) {
        // 若是ws开启状态
        websocketsend(agentData)

    } else if (websock.readyState === websock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback)
        }, 1000)
    } else {
        // 若未开启 ，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback)
        }, 1000)
    }
}






// 数据接收
function websocketonmessage(e) {
    let data = JSON.parse(e.data)
    if (data.action == "socketid") {
        localStorage.setItem("qyctSocketId", data.data)
        if (localStorage.getItem("token")) {
            bind_websocket_id({ socketid: data.data, });
        }
    }
}
// get 請求
function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 数据发送
function websocketsend(agentData) {
    var qsdata = qs.stringify(data.data);
    // 发送的参数  url + data
    var dataUrl = baseURL + data.url + '?' + qsdata + userToken
    var objData = {
        code: data.code,
        data: dataUrl,
        sign: data.sign
    }
    websock.send(JSON.stringify(objData))
}

// 关闭
function websocketclose(e) {
    console.log('关闭websocket')
}

// 创建 websocket 连接
function websocketOpen(e) {
    console.log('连接成功')
}

initWebSocket()

// 将方法暴露出去
export {
    sendSock,
    websock,
    initWebSocket
}


// 使用方法   



//-------------- Main.JS  挂-----------------
// import * as socketApi from '@/utils/ws' // 长连接 
// Vue.prototype.socketApi = socketApi
//-------------- 组件调用 -----------------
// let params = {
//     url: "/roletree_get",
//     data: {
//         code: "system",
//     },
// };
// this.socketApi.sendSock(params, (res) => {
//     console.log(res);
// });