<template>
    <div>
       <h1>输入网址错误</h1>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less">
    
</style>