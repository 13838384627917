 import Vue from 'vue'
 import App from './App'
 import router from './router'
 import store from './store'

 // --------ElementUI 引入---------
 import ElementUI from 'element-ui';
 import 'element-ui/lib/theme-chalk/index.css';
 import "./assets/myLess/element.less";
 import "./assets/css/specialReport.less";
 Vue.use(ElementUI);

 // 引入自定义的全局公共css----------
 import './assets/css/common.css'
 import './assets/font-awesome-4.7.0/css/font-awesome.min.css'
 // 引入echarts--------------
 import * as echarts from 'echarts';
 Vue.prototype.$echarts = echarts
 import * as socketApi from '../public/static/ws'
 Vue.prototype.socketApi = socketApi
 //打印
 import Print from 'vue-print-nb'
 Vue.use(Print); //注册


 //获取全局配置参数

//  import {
//      whitelist
//  } from "@/api/login.js"; //白名单获取机器码
 import {
     getListConfig
 } from "@/api/comm.js";
 // 判断手机
 function _isMobile() {
     let flag = navigator.userAgent.match(
         /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
     );
     return flag;
 }
 //手机或者平板端不做处理
 if (!_isMobile()) {
    let url = String(window.location.hash).substring(1,12);
    console.log("页面地址",url);
    if(url !="/screenshot"){
    //  whitelist().then((res) => {
    //      localStorage.setItem("machinecode", res.data);
    //      let config = JSON.parse(localStorage.getItem("config"));
    //      if (!config) {
    //          getListConfig().then(res => {
    //              if (res.code == 400200) {
    //                  //  document.title = res.data.systematic_name;
    //                  document.title = "重庆市人民医院";
    //                  localStorage.setItem("config", JSON.stringify(res.data));
    //              }
    //          });
    //      } else {
    //          document.title = "重庆市人民医院";
    //          //  document.title = config.systematic_name;
    //      }
    //  });
    }
 } else {
     let config = JSON.parse(localStorage.getItem("config"));
     if (!config) {
         getListConfig().then(res => {
             if (res.code == 400200) {
                 //  document.title = res.data.systematic_name;
                 document.title = "重庆市人民医院";
                 localStorage.setItem("config", JSON.stringify(res.data));
             }
         });
     } else {
         document.title = "重庆市人民医院";
         //  document.title = config.systematic_name;
     }
 }

 // ----------------工具配置--------------------
 Vue.config.productionTip = false
 Vue.config.devtools = true
 Vue.prototype.$log = console.log;

 new Vue({
     router,
     store,
     render: function (h) {
         return h(App)
     }
 }).$mount('#app')