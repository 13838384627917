import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		hasLogin: false
        
	},
	mutations: {
		// 实现登录 记住登录状态
		login(state) {
			state.hasLogin = true;
		},
		// 退出登录
		logout(state) {
			state.hasLogin = false;
		},
        setmenu(state, payload) {
            state.menu = payload.menu
        },
        updatePatrolCount(state, payload) {
            state.patrol = payload.patrol
        },
		// 登录检查  
		accessCheck(state) {
			if (!state.hasLogin) {
			}
		}
	}
})

export default store
