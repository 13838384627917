import Vue from 'vue'
import Router from 'vue-router'
// import resolve from 'path'
// 组件
import Layout from '../layout/index.vue' // 主出口
import Err404 from "../view/err/404.vue" //404

Vue.use(Router)

// -------------- 优化-------------------------------



const router = new Router({
    // 路由规则
    routes: [
        //测试登陆页面
        // --------------------------------
        // 用户注册
        {
            path: "/userRegistration",
            name: "userRegistration",
            component: () =>
                import("@/view/userRegistration/index.vue")
        },
        // 登录页
        {
            path: "/login",
            name: "login",
            component: () =>
                import("@/view/login/login.vue")
        },
        // 用户登陆页面
        {
            path: "/userLogin",
            name: "userLogin",
            component: () =>
                import("@/view/user/userLogin.vue")
        },
        // 重定向
        {
            path: "/",
            redirect: "/login"
        },

        // 首页
        {
            path: "/home",
            component: Layout,
            children: [{
                path: "/",
                name: "home",
                component: () =>
                    import("@/view/index.vue")
            }]
        },
        // {
        //     path: "/video-demo",
        //     name: "video-demo",
        //     component: (resolve) =>
        //         require(["@/view/physician_visits/index.vue"],resolve)
        // },
        // 测评管理
        {
            path: "/evaluate",
            name: "evaluate",
            redirect: "/user_evaluate",
            component: Layout,
            meta: {
                title: "测评管理"
            },
            children: [{
                    path: "",
                    name: "user_evaluate",
                    component: () =>
                        import("../view/evaluate/user_evaluate/index.vue"),
                    meta: {
                        title: "用户测评"
                    },
                    redirect: "/user_evaluate",
                    children: [{
                            path: "/user_evaluate",
                            name: "home_user_evaluate",
                            meta: {
                                keepAlive: true,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/evaluate/user_evaluate/home.vue")
                        },
                        {
                            path: "/user_evaluate/addUser",
                            name: "addUser",
                            meta: {
                                title: "添加测评用户",
                                keepAlive: false,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/evaluate/user_evaluate/addUser/index.vue")
                        },
                        {
                            path: "/user_evaluate/editUser",
                            name: "editUser",
                            meta: {
                                title: "编辑用户信息",
                                keepAlive: false,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/evaluate/user_evaluate/editUser/index.vue")
                        },
                        {
                            path: "/user_evaluate/report",
                            name: "report",
                            meta: {
                                title: "用户报告"
                            },
                            component: () =>
                                import("../view/report/index.vue")
                        },
                        {
                            path: "/user_evaluate/batchSetPermissions",
                            name: "batchSetPermissions",
                            meta: {
                                title: "批量设置权限"
                            },
                            component: () =>
                                import("../view/evaluate/user_evaluate/batchSetPermissions/index.vue")
                        },
                        {
                            path: "/user_evaluate/bulkImport",
                            name: "bulkImport",
                            meta: {
                                title: "批量导入用户信息",
                                keepAlive: false,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/evaluate/user_evaluate/bulkImport/index.vue")
                        },
                        {
                            path: "/user_evaluate/custom",
                            name: "custom",
                            meta: {
                                title: "自定义",
                                keepAlive: false,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/evaluate/user_evaluate/custom/index.vue")
                        },
                        {
                            path: "/user_evaluate/exportUserInfo",
                            name: "exportUserInfo",
                            meta: {
                                title: "批量导出用户数据",
                                keepAlive: false,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/evaluate/user_evaluate/exportUserInfo/index.vue")
                        }
                    ]
                },
                {
                    path: "",
                    name: "measure",
                    component: () =>
                        import("../view/evaluate/measure/index.vue"),
                    meta: {
                        title: "量表管理"
                    },
                    redirect: "/measure",
                    children: [{
                            path: "/measure",
                            name: "home_measure",
                            component: () =>
                                import("../view/evaluate/measure/home.vue")
                        },
                        {
                            path: "/measure/measure_classify",
                            name: "measure_classify",
                            meta: {
                                title: "量表分类"
                            },
                            component: () =>
                                import("../view/evaluate/measure/measure_classify/index.vue")
                        },
                        {
                            path: "/measure/measure_group",
                            name: "measure_group",
                            meta: {
                                title: "量表分组"
                            },
                            component: () =>
                                import("../view/evaluate/measure/measure_group/index.vue"),
                        },
                        {
                            path: "/measure/add_measure",
                            name: "add_measure",
                            meta: {
                                title: "添加量表"
                            },
                            component: () =>
                                import("../view/evaluate/measure/add/index.vue")
                        },
                        {
                            path: "/measure/do_measure_group",
                            name: "do_measure_group",
                            meta: {
                                title: "添加量表"
                            },
                            component: () =>
                                import("../view/evaluate/measure/measure_group/add/index.vue")
                        }

                    ]
                },
                {
                    path: "",
                    name: "permit",
                    component: () =>
                        import("../view/evaluate/permit/index.vue"),
                    meta: {
                        title: "权限管理"
                    },
                    redirect: "/permit",
                    children: [{
                            path: "/permit",
                            name: "home_permit",
                            component: () =>
                                import("../view/evaluate/permit/home.vue"),
                        },
                        {
                            path: "/permit/regulate",
                            name: "regulate",
                            meta: {
                                title: "管理权限"
                            },
                            component: () =>
                                import("../view/evaluate/permit/regulate/index.vue")
                        },

                    ]
                },
                {
                    path: "",
                    name: "interview",
                    component: () =>
                        import("../view/evaluate/interview/index.vue"),
                    meta: {
                        title: "晤谈检查"
                    },
                    children: [{
                        path: "/interview",
                        name: "home_interview",
                        component: () =>
                            import("../view/evaluate/interview/home.vue")
                    }]
                },
                {
                    path: "",
                    name: "psychological_census",
                    component: () =>
                        import("../view/evaluate/psychological_census/index.vue"),
                    meta: {
                        title: "心理普查"
                    },
                    children: [{
                        path: "/psychological_census",
                        name: "home_psychological_census",
                        component: () =>
                            import("../view/evaluate/psychological_census/home.vue")
                    }]
                },
            ]
        },
        // 信息管理
        {
            path: "/informtion",
            name: "information",
            component: Layout,
            meta: {
                title: "信息管理"
            },
            redirect: "/user_information",
            children: [
                // 测评报告管理
                {
                    path: "",
                    name: "evaluate_report",
                    component: () =>
                        import("../view/information/evaluate_report/index.vue"),
                    meta: {
                        title: "测评报告管理"
                    },
                    redirect: "/evaluate_report",
                    children: [{
                            path: "/evaluate_report",
                            name: "home_evaluate_report",
                            // --------添加处--------------
                            meta: {
                                keepAlive: true,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/information/evaluate_report/home.vue")
                        },
                        {
                            path: "/evaluate_report/report",
                            name: "evaluate_reportInfo",
                            meta: {
                                title: "用户报告",
                                keepAlive: false,
                                ifDoFresh: false
                            },
                            component: () =>
                                import("../view/report/index.vue")
                        },

                    ]
                },
                // 用户信息管理
                {
                    path: "",
                    name: "user_information",
                    component: () =>
                        import("../view/information/user_information/index.vue"),
                    redirect: "/user_information",
                    meta: {
                        title: "用户信息管理"
                    },
                    children: [{
                            path: "/user_information",
                            name: "home_user_information",
                            component: () =>
                                import("../view/information/user_information/home.vue")
                        },
                        {
                            path: "/user_information/surface",
                            name: "surface",
                            meta: {
                                title: "档案信息"
                            },
                            component: () =>
                                import("../view/information/user_information/surface/index.vue")
                        },
                        {
                            path: "/user_information/record",
                            name: "record",
                            meta: {
                                title: "档案信息报告"
                            },
                            component: () =>
                                import("../view/information/user_information/record/index.vue")
                        },
                    ]
                },
                // 录入数据
                {
                    path: "",
                    name: "evaluate_entry",
                    component: () =>
                        import("../view/information/evaluate_entry/index.vue"),
                    meta: {
                        title: "录入测评数据"
                    },
                    redirect: "/evaluate_entry",
                    children: [{
                        path: "/evaluate_entry",
                        name: "home_evaluate_entry",
                        component: () =>
                            import("../view/information/evaluate_entry/home.vue")

                    }]
                },
                // 导出数据
                {
                    path: "",
                    name: "evaluate_export",
                    component: () =>
                        import("../view/information/evaluate_export/index.vue"),
                    meta: {
                        title: "导出测评数据"
                    },
                    redirect: "/evaluate_export",
                    children: [{
                        path: "/evaluate_export",
                        name: "home_evaluate_export",
                        component: () =>
                            import("../view/information/evaluate_export/home.vue")

                    }]
                },
                // 晤谈报告管理
                {
                    path: "",
                    name: "interview_report",
                    component: () =>
                        import("../view/information/interview_report/index.vue"),
                    meta: {
                        title: "晤谈报告管理"
                    },
                    redirect: "/interview_report",
                    children: [{
                        path: "/interview_report",
                        name: "home_interview_report",
                        component: () =>
                            import("../view/information/interview_report/home.vue")

                    }]
                },
                // 合并打印
                {
                    path: "/evaluate_report/concisereport",
                    name: "concisereport",
                    component: () =>
                        import("../view/information/evaluate_report/concisereport.vue"),
                    meta: {
                        title: "报告单"
                    },
                }
            ]
        },
        // 在线问诊管理
        {
            path: "/online_medical_inquiries",
            name: "online_medical_inquiries",
            meta: {
                title: "在线问诊管理"
            },
            component: Layout,
            redirect: "/user_subscribe",
            children: [
                // 用户预约管理
                {
                    path: "",
                    name: "user_subscribe",
                    component: () =>
                        import("../view/online_medical_inquiries/user_subscribe/index.vue"),
                    meta: {
                        title: "用户预约管理"
                    },
                    children: [{
                        path: "/user_subscribe",
                        name: "home_user_subscribe",
                        component: () =>
                            import("../view/online_medical_inquiries/user_subscribe/home.vue")
                    }, ]
                },
                //在线视频
                {
                    path: "",
                    name: "online",
                    component: () =>
                        import("../view/online_medical_inquiries/online_video/index.vue"),
                    meta: {
                        title: "在线视频"
                    },
                    children: [{
                        path: "/online_video",
                        name: "online_video",
                        component: () =>
                            import("../view/online_medical_inquiries/online_video/home.vue")
                    }, ]
                }
            ]
        },
        // 统计数据分析
        {
            path: "/statistical_analysis",
            name: "statistical_analysis",
            meta: {
                title: "统计数据分析"
            },
            component: Layout,
            redirect: "/user_info_statistical",
            children: [
                // 用户信息统计
                {
                    path: "",
                    name: "user_info_statistical",
                    component: () =>
                        import("../view/statistical_analysis/user_info_statistical/index.vue"),
                    meta: {
                        title: "用户信息统计"
                    },
                    children: [{
                        path: "/user_info_statistical",
                        name: "home_user_info_statistical",
                        component: () =>
                            import("../view/statistical_analysis/user_info_statistical/home.vue")
                    }, ]
                },
                //量表信息管理
                {
                    path: "",
                    name: "measure_info_statistical",
                    component: () =>
                        import("../view/statistical_analysis/measure_info_statistical/index.vue"),
                    meta: {
                        title: "量表信息管理"
                    },
                    children: [{
                        path: "/measure_info_statistical",
                        name: "home_measure_info_statistical",
                        component: () =>
                            import("../view/statistical_analysis/measure_info_statistical/home.vue")
                    }, ]
                },
                //量表测评统计
                {
                    path: "",
                    name: "get_m_a_s",
                    component: () =>
                        import("../view/statistical_analysis/get_m_a_s/index.vue"),
                    meta: {
                        title: "量表信息管理"
                    },
                    children: [{
                        path: "/get_m_a_s",
                        name: "home_get_m_a_s",
                        component: () =>
                            import("../view/statistical_analysis/get_m_a_s/home.vue")
                    }, ]
                }
            ]
        },
        // 测评问卷管理
        {
            path: "/questionnaire_control",
            name: "questionnaire_control",
            meta: {
                title: "测评问卷管理"
            },
            component: Layout,
            redirect: "/control_questionnaire_result",
            children: [
                // 批阅测评问卷
                {
                    path: "",
                    name: "read_questionnaire",
                    redirect: "/read_questionnaire",
                    component: () =>
                        import("../view/questionnaire_control/read_questionnaire/index.vue"),
                    meta: {
                        title: "批阅测评问卷"
                    },
                    children: [{
                            path: "/read_questionnaire",
                            name: "home_read_questionnaire",
                            component: () =>
                                import("../view/questionnaire_control/read_questionnaire/home.vue")
                        },
                        {
                            path: "/read_questionnaire/read",
                            name: "read_ques",
                            meta: {
                                title: "批阅问卷"
                            },
                            component: () =>
                                import("../view/questionnaire_control/read_questionnaire/read/index.vue")
                        },
                    ]
                },
                // 问卷分类管理
                {
                    path: "",
                    name: "questionnaire_classify",
                    component: () =>
                        import("../view/questionnaire_control/questionnaire_classify/index.vue"),
                    meta: {
                        title: "问卷分类管理"
                    },
                    children: [{
                        path: "/questionnaire_classify",
                        name: "home_questionnaire_classify",
                        component: () =>
                            import("../view/questionnaire_control/questionnaire_classify/home.vue")
                    }, ]
                },
                // 测评问卷管理
                {
                    path: "",
                    name: "control_questionnaire_result",
                    component: () =>
                        import("../view/questionnaire_control/control_questionnaire_result/index.vue"),
                    meta: {
                        title: "测评问卷管理"
                    },
                    redirect: "/control_questionnaire_result",
                    children: [{
                            path: "/control_questionnaire_result",
                            name: "home_control_questionnaire_result",
                            component: () =>
                                import("../view/questionnaire_control/control_questionnaire_result/home.vue")
                        },
                        {
                            path: "/control_questionnaire_result/questionnaire_select",
                            name: "questionnaire_select",
                            meta: {
                                title: "挑选题目"
                            },
                            component: () =>
                                import("../view/questionnaire_control/control_questionnaire_result/select/index.vue")
                        },
                        {
                            path: "/control_questionnaire_result/questionnaire_look",
                            name: "questionnaire_look",
                            meta: {
                                title: "查看所有题目"
                            },
                            component: () =>
                                import("../view/questionnaire_control/control_questionnaire_result/look/index.vue")
                        },
                    ]
                },
                // 问卷题库管理
                {
                    path: "",
                    name: "question_bank",
                    component: () =>
                        import("../view/questionnaire_control/question_bank/index.vue"),
                    meta: {
                        title: "问卷题库管理"
                    },
                    redirect: "/question_bank",
                    children: [{
                        path: "/question_bank",
                        name: "home_question_bank",
                        component: () =>
                            import("../view/questionnaire_control/question_bank/home.vue")
                    }, ]
                },
            ]
        },
        // 在线训练管理
        {
            path: "/online_training",
            name: "online_training",
            meta: {
                title: "在线训练管理"
            },
            component: Layout,
            redirect: "/user_training",
            children: [
                // 用户和训练管理
                {
                    path: "",
                    name: "User_training",
                    component: () =>
                        import("../view/online_training/user_training/index.vue"),
                    meta: {
                        title: "用户和训练管理"
                    },
                    children: [{
                        path: "/user_training",
                        name: "home_user_training",
                        component: () =>
                            import("../view/online_training/user_training/home.vue")
                    }, ]
                },
                //训练统计分析
                {
                    path: "",
                    name: "training_statistics",
                    redirect: "/training_statistics",
                    component: () =>
                        import("../view/online_training/training_statistics/index.vue"),
                    meta: {
                        title: "游戏报告管理"
                    },
                    children: [{
                            path: "/training_statistics",
                            name: "Training_statistics",
                            component: () =>
                                import("../view/online_training/training_statistics/home.vue")
                        },
                        {
                            path: "/training_statistics/gameReport",
                            name: "gameReport",
                            meta: {
                                title: "预览用户游戏报告"
                            },
                            component: () =>
                                import("../view/online_training/training_statistics/viewGame/view.vue")
                        },
                    ]
                },
                //音乐
                {
                    path: "",
                    name: "music",
                    component: () =>
                        import("../view/online_training/music/index.vue"),
                    meta: {
                        title: "音乐放松"
                    },
                    children: [{
                        path: "/music",
                        name: "home_music",
                        component: () =>
                            import("../view/online_training/music/home.vue")
                    }, ]
                }
            ]
        },
        // 首页文章管理
        {
            path: "/article",
            name: "article",
            meta: {
                title: ""
            },
            component: Layout,
            redirect: "/article_list",
            children: [
                // 内容
                {
                    path: "",
                    name: "article_list",
                    redirect: "/article_list",
                    component: () =>
                        import("../view/article/content/index.vue"),
                    meta: {
                        title: "文章内容管理"
                    },
                    children: [{
                            path: "/article_list",
                            name: "home_article_list",
                            component: () =>
                                import("../view/article/content/home.vue")
                        },
                        {
                            path: "/article_list/Addarticle",
                            name: "Addarticle",
                            meta: {
                                title: "创建新文章"
                            },
                            component: () =>
                                import("../view/article/content/add/index.vue")
                        },
                    ]
                },
                //分类
                {
                    path: "",
                    name: "home_article_classify",
                    component: () =>
                        import("../view/article/classify/index.vue"),
                    meta: {
                        title: "文章分类管理"
                    },
                    children: [{
                        path: "/article_classify",
                        name: "article_classify",
                        component: () =>
                            import("../view/article/classify/home.vue")
                    }, ]
                }
            ]
        },
        // 体检卡
        {
            path: "/user_card",
            name: "user_card",
            meta: {
                title: "体检卡"
            },
            component: Layout,
            redirect: "/user_card_giveout",
            children: [
                // 体检卡发放管理
                {
                    path: "",
                    name: "user_card_giveout",
                    redirect: "/user_card_giveout",
                    component: () =>
                        import("../view/user_card/user_card_giveout/index.vue"),
                    meta: {
                        title: "体检卡发放管理"
                    },
                    children: [{
                        path: "/user_card_giveout",
                        name: "home_user_card_giveout",
                        component: () =>
                            import("../view/user_card/user_card_giveout/home.vue")
                    }]
                },
            ]
        },
        // 辅助应用管理 
        {
            path: "/auxiliary",
            name: "auxiliary",
            meta: {
                title: "辅助应用管理"
            },
            component: Layout,
            redirect: "/data_backup",
            children: [
                // 数据备份管理
                {
                    path: "",
                    name: "Data_backup",
                    component: () =>
                        import("../view/auxiliary/data_backup/index.vue"),
                    meta: {
                        title: "数据备份管理"
                    },
                    children: [{
                        path: "/data_backup",
                        name: "home_data_backup",
                        component: () =>
                            import("../view/auxiliary/data_backup/home.vue")
                    }, ]
                },
                // 本机数据局备份
                {
                    path: "",
                    name: "local_backup",
                    component: () =>
                        import("../view/auxiliary/local_backup/index.vue"),
                    meta: {
                        title: "本机数据局备份"
                    },
                    children: [{
                        path: "/local_backup",
                        name: "Lcal_backup",
                        component: () =>
                            import("../view/auxiliary/local_backup/home.vue")
                    }, ]
                },
                // 本机数据恢复
                {
                    path: "",
                    name: "data_recovery",
                    component: () =>
                        import("../view/auxiliary/data_recovery/index.vue"),
                    meta: {
                        title: "本机数据局备份"
                    },
                    children: [{
                        path: "/data_recovery",
                        name: "Data_recovery",
                        component: () =>
                            import("../view/auxiliary/data_recovery/home.vue")
                    }, ]
                },
                // 系统设置
                {
                    path: "",
                    name: "system_parameter",
                    component: () =>
                        import("../view/auxiliary/system_setup/index.vue"),
                    meta: {
                        title: "系统参数设置"
                    },
                    children: [{
                        path: "/system_parameter",
                        name: "home_system_parameter",
                        component: () =>
                            import("../view/auxiliary/system_setup/home.vue")
                    }, ]
                },
                // 门诊号/住院号设置
                {
                    path: "",
                    name: "number_set",
                    component: () =>
                        import("../view/auxiliary/user_rule/index.vue"),
                    meta: {
                        title: "门诊号/住院号设置"
                    },
                    children: [{
                        path: "/number_set",
                        name: "home_number_set",
                        component: () =>
                            import("../view/auxiliary/user_rule/home.vue")
                    }, ]
                },
                // 系统安全设置
                {
                    path: "",
                    name: "system_safety",
                    component: () =>
                        import("../view/auxiliary/system_safety/index.vue"),
                    meta: {
                        title: "系统安全设置"
                    },
                    children: [{
                        path: "/system_safety",
                        name: "home_system_safety",
                        component: () =>
                            import("../view/auxiliary/system_safety/home.vue")
                    }, ]
                },
                // 系统日志
                {
                    path: "",
                    name: "system_log",
                    component: () =>
                        import("../view/auxiliary/system_log/index.vue"),
                    meta: {
                        title: "系统日志"
                    },
                    children: [{
                        path: "/query_log",
                        name: "query_log",
                        component: () =>
                            import("../view/auxiliary/system_log/home.vue")
                    }, ]
                },

            ]
        },
        // 用户答题
        {
            path: "/answer",
            name: "answer",
            meta: {
                title: "用户答题"
            },
            component: () =>
                import("../view/answer/index.vue"),
            redirect: "/answer_content",
            children: [{
                    path: "/answer_content",
                    name: "answer_content",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/answer_content/index.vue"),
                },
                {
                    path: "/answer_content/answer_list",
                    name: "answer_list",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/answer_list/index.vue"),
                },
                {
                    path: "/answer_content/answer_ques",
                    name: "answer_ques",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/answer_ques/index.vue"),
                },
                {
                    path: "/answer_content/ques_content",
                    name: "ques_content",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/answer_ques/ques_content/index.vue"),
                },

                {
                    path: "/answer_content/answerChart",
                    name: "answeChart",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/report/index.vue"),
                },
                {
                    path: "/answer_content/answerUserMeasure",
                    name: "answerUserMeasure",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/userMeasure/index.vue"),
                },
                {
                    path: "/answer_content/userVideo",
                    name: "userVideo",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/userVideo/index.vue"),
                },
                {
                    path: "/answer_content/information",
                    name: "userInformation",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/user_information/index.vue"),
                },
                {
                    path: "/answer_content/information_content",
                    name: "informationContent",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/user_information/information_content/index.vue"),
                },
                {
                    path: "/answer_home",
                    name: "answer_home",
                    meta: {
                        keepAlive: true,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/portal/index.vue"),
                },
                {
                    path: "/answer_home/info",
                    name: "answer_info",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/portal/portal_info/index.vue"),
                },
                {
                    path: "/answer_content/game",
                    name: "answer_game",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/game/index.vue"),
                },
                {
                    path: "/answer_content/music",
                    name: "answer_music",
                    meta: {
                        keepAlive: false,
                        ifDoFresh: false
                    },
                    component: () =>
                        import("../view/answer/music/index.vue"),
                },

            ]
        },
        {
            path: "/user_answer_list",
            name: "user_answer_list",
            meta: {
                title: "用户答题列表"
            },
            component: () =>
                import("../view/answer/answer_list/answer_list.vue"),

        },
        {
            path: "/user_answer_content",
            name: "user_answer_content",
            meta: {
                title: "答题内容"
            },
            component: () =>
                import("../view/answer/answer_content/answer_content.vue"),

        },
        //用户医惠系统对接报告列表
        {
            path: "/user_answer_report_list",
            name: "user_answer_report_list",
            meta: {
                title: "报告列表"
            },
            component: () =>
                import("../view/answer/answer_report/answer_list.vue"),

        },
        //用户医惠系统对接报告内容
        {
            path: "/user_answer_report",
            name: "user_answer_report",
            meta: {
                title: "报告内容"
            },
            component: () =>
                import("../view/answer/answer_report/answer_report.vue"),

        },
        // 用户个人中心
        {
            path: "/answerUserInfo",
            name: "answerUserInfo",
            component: () =>
                import("@/view/user/index.vue")
        },
        // 报告 - PDF打印下载
        {
            path: "/downpdf",
            name: "downpdf",
            meta: {
                title: "用户答题"
            },
            component: () =>
                import("../view/report/down.vue")
        },
        // 报告 - PDF打印下载
        {
            path: "/condownpdf",
            name: "condownpdf",
            meta: {
                title: "报告打印"
            },
            component: () =>
                import("../view/information/evaluate_report/down.vue")
        },
        // 上传图片
        {
            path: "/screenshot",
            name: "screenshot",
            meta: {
                title: "截图上传"
            },
            component: () =>
                import("../view/report/screenshot.vue")
        },
        // 团体报告 打印下载
        {
            path: "/downpdfGroup",
            name: "downpdfGroup",
            meta: {
                title: "团体报告"
            },
            component: () =>
                import("../view/evaluate/permit/group/index.vue")
        },
        // 多个团体报告 打印下载
        {
            path: "/downpdfGroup2",
            name: "downpdfGroup2",
            meta: {
                title: "团体报告"
            },
            component: () =>
                import("../view/evaluate/permit/group/index2.vue")
        },
        //  问卷预览-打印
        {
            path: "/questionnaire_print",
            name: "questionnaire_print",
            meta: {
                title: "问卷打印"
            },
            component: () =>
                import("../view/questionnaire_control/control_questionnaire_result/print/index.vue")
        },
        //  问卷批阅
        {
            path: "/read_questionnaire_look",
            name: "read_ques_look",
            meta: {
                title: "问卷批阅"
            },
            component: () =>
                import("../view/questionnaire_control/read_questionnaire/look/index.vue")
        },
        // 游戏报告打印下载
        {
            path: "/gameReportDown",
            name: "gameReportDown",
            meta: {
                title: "用户答题"
            },
            component: () =>
                import("../view/online_training/training_statistics/viewGame/print.vue")
        },
		// 量表过期页面
		{
		    path: "/expiration_page",
		    name: "expiration",
		    component: () =>
		       import("@/view/expiration/index.vue")
		},
        // 错误网页
        {
            path: "*",
            name: "404",
            component: Err404
        }
    ]
})


// 路由守卫
router.beforeEach((to, from, next) => {
    //console.log(to.path);

    // 1. 判断是否是登录页
    if (to.path === '/login' || to.path === '/userRegistration' || to.path === '/userLogin') {
        next()
        // 非登录页面 (其他页面)
    } else {
        // 2. 判断是否登录过
        const token = localStorage.getItem('token')
        const usertoken = localStorage.getItem('usertoken')

        // if (to.path.indexOf("answer") > -1 || to.path == '/login') {
        //     usertoken ? next() : next('/userLogin')
        // } else {
        // token ? next() : next('/login')
        // }
        next()

    }
})
// 导出
export default router